.authenticationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
}

.errorContainer {
  padding: 16px;

  .error {
    text-align: center;
    color: #cc0000;
  }

  .authFormContainer {
    border: 2px #888 solid;
    border-radius: 16px;
    padding: 32px;
    margin-top: 128px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .authenticationContainer {
    gap: 16px;
  }
  .errorContainer {
    max-width: 100vw;
    box-sizing: border-box;

    .error {
      font-size: 1.2rem;
    }

    .authFormContainer {
      border: none;
      padding: 16px;
      margin-top: 32px;
    }
  }
}
