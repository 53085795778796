html,
body,
#root {
  margin: 0;
  background-color: #ffffff;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
