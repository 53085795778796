@import "./vars";

.modal-paper {
  max-width: unset;
}

.title {
  color: $primary-color;
  font-size: 24px;
  font-weight: 600;
}

.profileModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 500px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .profile {
    display: flex;
    align-items: center;
    gap: 24px;
    height: 100%;
    width: 100%;
    padding: 16px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .part {
      display: flex;
      gap: 4px;
    }

    .partTitle {
      text-decoration: underline;
      text-underline-offset: 4px;
      margin-bottom: 8px;
    }

    .person {
      height: 200px;
      width: 100%;
      background: $primary-color;
      color: white;
      padding: 16px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .company {
      height: 200px;
      width: 100%;
      border: 2px solid $primary-color;
      padding: 8px;
      border-radius: 5px;
      background: $primary-color;
      color: white;
      padding: 16px;
    }
  }
}
