.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: "Rubik", sans-serif;
}

.button {
  width: 110px;
  font-family: "Rubik", sans-serif;
}

.button:hover {
  background: #259a8e;
  color: white;
}

.link {
  align-self: center;
  margin-top: 16px;
}

.modal-root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  
}

.modal-paper {
  padding: 24px;
  min-height: 200px;
  max-height: 100%;
  min-width: 450px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.notFound {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
}

@media only screen and (max-width: 768px) {
  .modal-paper {
    padding: 16px;
    min-width: 150px;
    max-width: 95%;
  }

  .notFound {
    gap: 32px;
  }
}

.geoloc-icon > svg {
  width: 30px;
  height: 30px;
}

#notistack-snackbar {
  font-family: "Rubik";
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.margin-right {
  margin-right: 16px !important;
}

.big-margin-right {
  margin-right: 32px !important;
}

.animationContainer {
  width: 500px;
  height: 400px;
}

@media only screen and (max-width: 768px) {
  .animationContainer {
    width: 250px;
    height: 200px;
  }
}
