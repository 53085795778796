.header {
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-self: stretch;

  .logo {
    width: 400px;
    height: 50px;
    margin-right: 32px;
    cursor: pointer;
  }

  .headerLeft,
  .reservationDates,
  .reservationControls,
  .reservationDatesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .reservationDate {
    margin-right: 32px;
  }
  .reservationDates {
    gap: 16px;
  }

  .rightButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .resettingContainer {
    padding: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    padding: 16px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;

    .logo {
      width: 250px;
      height: 30px;
      margin-bottom: 8px;
      margin-right: 0;
    }

    .reservationControls,
    .headerLeft {
      flex-direction: column;
    }

    .reservationDatesContainer {
      gap: 8px;

      .reservationDates {
        margin-top: 8px;
        margin-right: 0;
        gap: 8px;

        .reservationDate {
          margin-right: 0;
          width: 120px;
        }
      }
    }
    .profileButton {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .resetButton {
      position: absolute;
      right: 10px;
      top: 54px;
    }
  }
}

@media only print {
  .logo {
    width: 300px !important;
    height: 35px !important;
  }

  .profileButton,
  .resetButton,
  .exportButton {
    display: none !important;
  }
}
