@import "./vars";

.reservations_container {
  padding: 8px 56px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .header_row {
    background: $primary-color;
    .first_cell {
      color: #fff;
    }
    .middle_cell {
      color: #fff;
    }
    .last_cell {
      color: #fff;
    }
  }

  .first_cell {
    border: 3px solid black;
    border-right: 1px solid black;
    width: 500px;
  }
  .middle_cell {
    border: 3px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
  }
  .last_cell {
    border: 3px solid black;
    border-left: 1px solid black;
  }
  .row {
    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.06);
    }
  }
}

.reservationFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px;
    gap: 32px;
    max-width: 1000px;

    @media screen and (max-width: 768px) {
      gap: 8px;
    }
  }

  .stepper {
    width: 100%;
    max-width: 960px;
  }
}

.confirmation {
  padding-top: 64px;
  text-align: center;
}


@media screen and (max-width: 768px) {
  .reservationFormContainer {
    .formContainer {
      padding: 0;
      padding-bottom: 8px;
    }
  }
}
