.leaflet-tooltip-left.myCSSClass::before {
  border-left-color: cyan;
}
.leaflet-tooltip-right.myCSSClass::before {
  border-right-color: cyan;
}

.leaflet-tooltip {
  border: unset;
  background: #259a8e;
  font-size: 18px;
  padding: 18px;
  color: white;
  transform: translate3d(300px, 300px, 0px);
}
.leaflet-tooltip::before {
  border-bottom-color: #259a8e;
}

.leaflet-div-icon {
  background: unset;
  border: unset;
}

.leaflet-div-icon > svg {
  width: 50px;
  height: 50px;
  stroke: #fff;
  filter: drop-shadow(2px 2px 5px #259a8e);
}
.leaflet-div-icon > svg > path {
  fill: #259a8e;
}
.leaflet-div-icon > svg > g > path {
  fill: #259a8e;
}

.leaflet-popup-content-wrapper {
  background: #259a8e;
  border: unset;
  font-size: 14px;
  padding: 8px;
  color: white;
}

.leaflet-popup-tip {
  background: #259a8e;
}

.leaflet-container a.leaflet-popup-close-button {
  color: #fff;
  margin: 4px;
  font-size: 24px;
}

.leaflet-container .leaflet-control-zoom a {
  color: #259a8e;
  background: #fff;
  text-decoration: unset;
  padding: 4px;
  margin: 4px;
  border-radius: 5px;
}

.leaflet-container {
  font-family: "Rubik", sans-serif;
}

.leaflet-container a.leaflet-control-layers-toggle {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAeCAYAAAA/xX6fAAAACXBIWXMAABc5AAAXOQF8hNrOAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAA79JREFUSIm9l11om2UUgJ/zpb+uk8FEL+oQsUlX7I06wauJoOKF3vSHQVlF2yQd2tkknRs4R71RWLFJt67FNFkrCGOsay9kMGSK6M02naIw2ZLUTawVhTL3Uzfb5PuOF21+TPO1iXaey3POe573e897zns+UVVKEefQUKVUpg6LUH17nq4Zv/9OKeulFKAzOvSgaGoCeGpZ9a3DlOZLu3w/FRvDKNbRFT60XTR1IQcG8LhZphdc0YHn1g0oIK5IqAfD+hR4YIWDshk1TtePBt8RkDXjrXakjSMjNcmKxTFVbV0r0HK4j01dePlH774bJQMbIkGXqUwi0lgcbEkU4kBTwuP/oZC94JE6w6GXTOR8qTAAAZfAWWd0sKWgPfcLZWLC4fpj5oCKHLDbTAmiCEO1szd7P+/rS60ANgwPbzYrFo8Bz/9HUL58kRJjxxV3z+8ZoPNo8DGxZBJ4eJ1hafkFNVri3p7zUhcZ2CkqYeCeuwRLy4KodBvGUq7WrJ/1EBXdIKpKXXTgCVFjUuChu8SaVdGWhDtwzgCYdvd+Y6i1DeGzdUeJfplMprYl3IFzkHP1Y97eudrZmy8IchAo7QmxE2X0Xqvm2auvvflbll+g09RHgztU5Siw4V+i5hU6Ex7/iXyDbWurHx3YqmJMAQ0lwhKWYTZNd+65WMiYuTQOdYSxrH2xrkAmj1vH+jdaZvk40Fwk7FSyXNqvvuK7nlY4I6GnRQmpyuuJLt9ZcUVCXlUdAiqAlKJvJzyBg5kdgdRFgnsFeRdw2IBUkP74rzfe0r4+K73OGQm9AbwPlKVj2xX+8eoq0/19+54/04q60eAzhshx4P482DXFaEt4ej5JKxpHRmoWyxfGgdwGviAq3au1tsuINMXdvksZ6Hhwi5FiEuTJZdV3UibNsVd9V9I+DZGgy0SmgEez38+MOGiJdfq/ylya+tGB+1Qcx0Bzx4XroO1xT+BUWrElFKquqpEPAP6a1125Q5QrOtiE6ofAxmwIOSNqtsW8vXOQ/zxl8/Ue2RpdkZ98sXnWltZtqt2vra1mxrdQWbgiwRdBPgI2ZU+F05UGOy92+q/l+to8a7dUpCPh9p1csTm7OnwkfNjpkNRU3qv/s2C0xDw9XwOFc6/EVGi2GzFWHaKW63CMf9622yrahRoVgg4DVTm2k4Yj2XG5Y+8tu5hFDcL10UGvqh4Bym1cTEX3T3sC/bpGHy568naFD23HsE6wcjadQ6y2uLv3TDFx/vdRvyQgLP3MUJ08AsCd8u7E7t0Lpaz/G/EuumulWqYIAAAAAElFTkSuQmCC")
    center no-repeat;
}

.leaflet-control-geosearch form input {
  font-size: 14px;
}

.leaflet-top {
  z-index: 1001;
}

.markerMission svg {
  width: 50px !important;
  height: 50px !important;
}

.markerMission.orange svg path {
  fill: #ff7b06;
  stroke: #fff;
}
.markerMission.gray svg path {
  fill: gray;
  stroke: #fff;
}
.markerMission.green svg path {
  fill: green;
  stroke: #fff;
}

@media only screen and (max-width: 768px) {
  .leaflet-container a.leaflet-control-layers-toggle {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAUCAYAAABvVQZ0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAKaSURBVDhPpVTPS1RRFD7nPStKDHQRQkGE88ZFIYT9WEWUyxYuIggS0azRLNMZLU3LkCKjZp6DTtrMa1oEgYsi6A8oKCraiLSI8DWtIlq0SEK0GX2378x7Dk2OpvXBB+d859xz7zvv3Mv0Fxhxs5aYekhR2G4OPfbkgli2mDES02lD5jyRGoBbAs4ge4BVOjoV6Mlkk/5AwWKVyZFyZyFzG2adq+ThEWl6u32q/Yvn57CkmD8e2a+YYzD3uEpBTCqisx8Dodeen0VeMfSnAUoEZpmrrIjvyO0uy8zee9va54iQLVY5OrzJKZq/CfMMWCTaKoEDUtwh7WIq0PGDfVakmhVLfw5lw/+Gl6S4S0OhE3D+p5DgALE6qSl2ruCsvRDmXH3N+An2K1YXcj/AnzCPoqg0f7urrAoyHp12IDQuTt7f9FuRSqV4FOZhV1kRz7F5K8bjg+cTV1iREnxrPSt6NdUcmvQnwpsVadcQa5N4NmspRh3d6U01dU37EmYV1h7EbDxgI2EOIygL846MpEZUknHZIr6Hb6jfbweCY+IY1tBxUkomYRuYXByNW3Dk0+bBQWK+bp8Opn2WuRe7xqHtBt9Bb4H+ZqcVXpdW2mVofaAOvmBi9weg+cWK+AZm8BxcDXyKEwRxgk8Vd82trHGdcuhhqiX42R83dyimEeQckbXIi7FyeqaaO2fyemIkIvUIRmGWgu/BNnz2M4kJjMRQDTaU+C5wGrlddmlVko7VyE1Y2mBf3NzHTHdgykWX2bu0wHRfV9QIexDcCE7gIrXhfVv+oi/CsMxyJMvCBlB2nQCrQcE46VrQbur46vk5FCwmMGJjOq2fk8dRxqQYnAWvOno6mmpaw+P4OzA6tbgq3fIYoH9PPLkAiH4BX6XedKszzXQAAAAASUVORK5CYII=")
      center no-repeat;
    width: 24px;
    height: 24px;
  }
  .leaflet-touch .leaflet-bar a {
    width: 24px;
    height: 24px;
  }
  .leaflet-container {
    height: calc(100vh - 225px);
  }
}

.leaflet-control-layers-base label {
  cursor: pointer;
}
