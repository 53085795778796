.mapScreenRoot {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mapContainer {
  flex: 1;
}

.myLocation {
  position: absolute;
  top: 112px;
  left: 10px;
  z-index: 1000;
  cursor: pointer;
  background: #259a8e;
  padding: 13px;
  border-radius: 5px;
  color: #fff;
}

.circlePopup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;

  .reservationButton {
    background: #fff;
    width: 100px;
  }
}

.hint {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  gap: 24px;
}

.mapModalContainer {
  width: 950px;
  height: 750px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.modalHeader {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
}

.clickConfirm {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .myLocation {
    top: 100px;
    padding: 10px;
  }

  .secondHint {
    display: none;
  }

  .hint {
    padding: 8px;
    gap: 8px;
  }
}
