@import "./vars";

.title {
  font-weight: 600;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 800px;

  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 10px;
    padding: 16px 24px;
  }

  &__title {
    font-weight: 500 !important;

    @media screen and (max-width: 768px) {
      margin-top: 12px !important;
    }
  }

  &__standalone {
    margin-top: 8px;
    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
  }

  &__group {
    display: flex;
    gap: 8px;
    width: 100%;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    .keepContact {
      & span {
        font-size: 15px;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 16px;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 4px;
    }
  }

  &__notFullWidth {
    min-width: 350px !important;
  }

  @media only screen and (max-width: 768px) {
    &__notFullWidth {
      min-width: 300px !important;
      align-self: stretch;
    }
  }

  &__optionalLocation {
    margin-top: -8px;
  }

  &__buttons {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    @media screen and (max-width: 768px) {
      gap: 4px;
      button {
        font-size: 12px;
      }
    }
  }

  &__gpsChooseButton {
    width: 100%;
  }

  &__fieldTitle {
    flex: 1;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
  }

  &__fieldControl {
    flex: 1;
  }

  .location__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 768px) {
      gap: 8px;
    }

    &__title {
      font-weight: 500;
      margin-top: 4px;
    }
    &__address {
      width: 45%;
    }
    &__zipCode {
      width: 13%;
    }
    &__city {
      width: 25%;
    }
    &__country {
      width: 17%;
    }

    @media screen and (max-width: 768px) {
      .form__group {
        flex-direction: column;
      }
      &__address {
        width: 100%;
      }
      &__zipCode {
        width: 100%;
      }
      &__city {
        width: 100%;
      }
      &__country {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .padding_left {
    padding: 0 12px;
  }
}
