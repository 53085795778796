.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
}

.topDate {
  font-size: 1.5em !important;
  font-weight: bold !important;
  text-align: center;
}

.missionRoot {
  min-width: 1000px;
  padding-bottom: 64px;
}

.section {
  font-weight: bold !important;
  font-size: 1.2em !important;
  margin-top: 32px !important;
  margin-bottom: 16px !important;
}

.info {
  padding-left: 32px;

  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}

.noteContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 16px;
}

.noteDate {
  color: #999;
}

@media only print {
  .container {
    gap: 16px !important;
  }
  .topDate {
    font-size: 1.3em !important;
  }
  .section {
    font-size: 1.1em !important;
  }
  .missionRoot {
    min-width: 90% !important;
  }
  @page {
    margin: 0 !important;
  }
  body {
    margin: 1.6cm !important;
  }
}
