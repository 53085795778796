.title {
  width: 100%;
  text-align: left;
}

.form {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.textField {
  width: 300px;
}

.confirmContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .title {
    text-align: center;
    font-size: 1.2rem;
  }

  .form {
    flex-direction: column;
  }

  .textField {
    width: 250px;
  }
}
