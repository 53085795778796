.container {
  flex: 1;
  background-color: #ccc;
  text-align: center;
}

.above,
.below {
  padding: 64px;
}

iframe {
  border: none;
  width: 90vw;
  height: 85vh;
}

@media only screen and (max-width: 768px) {
  .above,
  .below {
    padding: 32px;
  }

  iframe {
    width: 100vw;
  }
}
