.admin_popup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.admin_popup_row_static {
  margin-right: 8px;
}

.bold {
  font-weight: 600;
}

.admin_popup_link {
  width: 100px;
  text-align: center;
  font-weight: 600;
}

.marginVertical {
  margin: 16px 0;
}

.link {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 16px;
}

.link a {
  color: #ffffff;
}

.missionSheet {
  background: white !important;
  color: #259a8e;
  margin: 4px !important;
}
